// import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { productAPI } from "../../Api";
import { Navigation, ProductCard } from "../../Components";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import "./Category.scss";
// import { toast } from "react-toastify";

import defaultImage from '../../Assets/Images/Product1.png'
// import get from "lodash/get";
import { CategoryCard } from "../../Components";
import SubCategoryCard from './SubCategory/SubCategoryCard'
import InfiniteScroll from 'react-infinite-scroller';
import LoadingProducts from "../../Components/Loader/LoadingProducts";
import Loader from "../../Components/Loader";
import { productList } from "../../../api/request.api";
import Carousel from "../../Components/Caraousel/Caraousel";
import Container1 from "../../Components/Container/Container1";
import { LiaDotCircle } from "react-icons/lia";
import { IoIosArrowDropdown } from "react-icons/io";



const mapStateToProps = ({ auth = {}, categories = {} }) => ({
  auth,
  categories,
});

export const RadioButton = ({
  children, 
  onClick,
  active
}) => {
  return (
    <button
      className="radius-first btn-lg flex flex-row justify-between items-center w-full"
      onClick={onClick}
    >
      <span className="text-medium">
        {
          children
        }
      </span>
      <LiaDotCircle size={'1.8rem'} color={active ? '#10847E' : '#E6EBF4'}/>
    </button>
  )
}

const Category = () => {
  const {
    auth = {},
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);

  const { isLoggedIn } = auth;
  const [categoryName, setCategory] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categoryImage, setCategoryImage] = useState('');
  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryId, setSubcategoryId] = useState(null)
  const [productsList, setProductsList] = useState([]);
  const { category = "" } = useParams();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showLoadingCards, SetShowLoadingCards] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingMore, setLoadingMore] = useState(false);

 
  let color_generator = 0;
 
  const subtabhandler = (subcategory, id) => {
    // history.replace(subcategory);
    setSubCategoryName(subcategory)
    setSubcategoryId(id)
  };

  const tabhandler = (category) => {
    history.replace(category);
    setCategory(category)
  };

  useEffect(() => {
    categoryList
      && categoryList.map((item) => {
        if (item.name === categoryName) {
          let subcategory_all = {
            id: item.id,
            image: item.image,
            name: item.name,
          }

          // let all_category = item.sub_categories.unshift(subcategory_all)

          if (item.sub_categories.length > 0) {
            setSubCategoryName(item.name)
            setSubcategoryId(item.id)
            setCategoryId(null)
          }

          else {
            setCategory(categoryName);
            setCategoryImage(item.image);
            setCategoryDescription(item.description)
            setSubCategoryName(categoryName)
            setCategoryId(item.id)
            setSubcategoryId(item.id)
          }

        setCategoryImage(item.image);
        }

        return 0
      })

  }, [categoryName]);

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    if (subCategoryName && subCategoryId && page) {
      setProductsList([]);
      fetchProducts(1);
    }

    setSubCategoryName(subCategoryName);
  }, [subCategoryName]);

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {

      if (category) {
        setCategory(category);

        const descOfCategory = categoryList.filter(item => {
          return (
            item.name === category
          )
        })

//        setCategoryDescription(descOfCategory[0].desc);

      } else {
        let firstCategory = categoryList[0]["name"];
        let firstCategoryDesc = categoryList[0]["description"]
        setCategory(firstCategory);
        console.log('categoryImage' ,categoryList[0]["image"]);
        setCategoryImage(categoryList[0]["image"]);
        setCategoryDescription(firstCategoryDesc);
        setCategoryId(categoryList[0]["id"])
      }
    }

    setCategoryImage(categoryList[0]?.image);
  }, [categoryList]);

  const fetchCategories = async () => {
    dispatch(actionsCreator.FETCH_CATEGORIES());
  };

  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when the component unmounts
    const handleOutsideClick = (event) => {
      const myElement = filtorBtnRef?.current;

      if(!myElement.contains(event.target)){
        toggleFilter(false);
      }
    }


    document.addEventListener('click', handleOutsideClick);


    return () => {
      isMounted.current = false;
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const loadMore = () => {
    if (!loadingMore && isMounted.current) {
      setLoadingMore(true);  // Set loadingMore to true to prevent concurrent requests
      fetchMoreProducts(page + 1)
        .finally(() => setLoadingMore(false));  // Reset loadingMore on completion (success or failure)
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, arguments), delay);
    };
  };

  const debouncedLoadMore = debounce(loadMore, 500);

  const fetchMoreProducts = async (newPage) => {
    try {
      const response = await productAPI.fetchPagedProducts({
        subCategoryName,
        subCategoryId,
        page: newPage,
      });

      if (response.data.data.length === 0) {
        setHasMore(false);
      } else {
        setProductsList((prevProducts) => {

          const uniqueProducts = [
            ...new Map([...prevProducts, ...response.data.data].map((item) => [item.id, item])).values(),
          ];

          setPage(newPage);
          return uniqueProducts;
        });
      }

    } catch (error) {
      console.log("Api error")
    }
  };


  const fetchProducts = async (page) => {
    try {
      SetShowLoadingCards(true)
      const response = await productAPI.fetchPagedProducts({ subCategoryName, subCategoryId, page });
      setProductsList(response.data.data);
      SetShowLoadingCards(false);
      if (response.data.data.length === 0) {
        setHasMore(false)
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchCategories();
    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }
  }, [isLoggedIn]);

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const [showFilter, toggleFilter] = useState(false);
  const filtorBtnRef = useRef(null);

  return (
    <div
      className="flex w-full responsive-flex-row"
      style={{
        paddingTop : '1rem',
        minHeight : '60vh',
        paddingBottom : '2rem'
      }}
    >
      <Container1 
        header1={'Filter'}
        className="flex-1 filter-lg"
        style={{
          maxWidth : '30%',
          padding : '0 4rem',
        }}
      >
        <Container1
          header2={'Categories'}
          style={{
            marginTop : '1rem'
          }}
        >
          {
            categoryList.map((item, index) => {
              return (
                <RadioButton
                  active={categoryName === item.name}
                  onClick={() => tabhandler(item.name)}
                >
                  {
                    item.name
                  }
                </RadioButton>
              );
            }) 
          }
        </Container1>

        <Container1
          header2={'SubCategories'}
          style={{
            marginTop : '1rem'
          }}
        >
          <RadioButton
            active={subCategoryName === categoryName}
            onClick={() => subtabhandler(categoryName, categoryId)}
          >
            All
          </RadioButton>
          {
            categoryList.map((item) => (
              item.name === categoryName && item.home_page &&
                item.sub_categories.length > 0 && item.sub_categories.map((subitem, index) => {
                  return (
                    <RadioButton
                      onClick={() => subtabhandler(subitem.name, subitem.id)}
                      active={subCategoryName === subitem.name}
                    >
                      {
                        subitem.name
                      }
                    </RadioButton>
                  );
                })))
          }
        </Container1>
      </Container1>

      {/* Filter buttons for mobile and tablets */}
      <div
        className="block-md-only relative p-1"
      >
        <div className="flex flex-row justify-between items-center w-full">
          <p className="text-xxlarge text-first medium-bold">
            Products
          </p>

          <button
            ref={filtorBtnRef}
            className="text-second text-large medium-bold items-center gap-10"
            style={{
              float : 'right'
            }}
            onClick={() => toggleFilter(prev => !prev)}
          >
            {
              categoryName
            }
            <IoIosArrowDropdown color={'#10847E'} size={'1.2rem'}/>
          </button>
        </div>

        {
          <div
            className="flex flex-col absolute p-1"
            style={{
              top : '2rem',
              right : '0',
              backgroundColor : 'white', 
              width : '18rem' ,
              opacity : showFilter ? '1' : '0',
              maxHeight : showFilter ? '20rem' : '0',
              zIndex : '100',
              overflowX : 'hidden',
              overflowY : 'scroll',
              transition : 'all 200ms',
              boxShadow : '0 0 10px #f2f2f2'
            }}
          >
            <Container1
              header2={'Categories'}
              style={{
                marginTop : '1rem'
              }}
            >
              {
                categoryList.map((item, index) => {
                  return (
                    <RadioButton
                      active={categoryName === item.name}
                      onClick={() => {
                        tabhandler(item.name)
                        toggleFilter(false);
                      }}
                    >
                      {
                        item.name
                      }
                    </RadioButton>
                  );
                }) 
              }
            </Container1>

            <Container1
              header2={'SubCategories'}
              style={{
                marginTop : '1rem'
              }}
            >
              <RadioButton
                active={subCategoryName === categoryName}
                onClick={() => {
                  subtabhandler(categoryName, categoryId)
                  toggleFilter(false);
                }}
              >
                All
              </RadioButton>
              {
                categoryList.map((item) => (
                  item.name === categoryName && item.home_page &&
                    item.sub_categories.length > 0 && item.sub_categories.map((subitem, index) => {
                      return (
                        <RadioButton
                          onClick={() => {
                            subtabhandler(item.name, categoryId)
                            toggleFilter(false);
                          }}
                          active={subCategoryName === subitem.name}
                        >
                          {
                            subitem.name
                          }
                        </RadioButton>
                      );
                    })))
              }
            </Container1>
          </div>
        }

      </div>

      <Container1
        id={'products'}
        style={{
          padding : '0 0.8rem'
        }}
        className="flex-1"
      >
        {
          productsList.length > 0 ?
            (
              <InfiniteScroll
                className="responsive-cards-3-1 w-full"
                style={{
                  marginTop : '1rem'
                }}
                loadMore={debouncedLoadMore}
                hasMore={hasMore}
                // loader={<div className="make-inline"><LoadingProducts number={6} /></div>}
              >
                {
                  <>
                    { 
                      productsList.map((item) => {
                        return (
                            <div style={{
                              gridColumn : 'span 1',
                              width : '100%',
                              // overflow : 'hidden'
                            }}>
                              <ProductCard
                                title={item.product_name}
                                quantity={item.description}
                                price={item.price}
                                image={item.photo}
                                id={item.id}
                                description={item.description}
                                oldprice={item.market_price}
                                outofstock={item.out_of_stock}
                                quantity_remaining={item.quantity_remaining}
                              />
                            </div>
                        );
                      })
                    }
                  </>
                }
              </InfiniteScroll>
            )
            :
            (
              hasMore?
              <div className="flex flex-row justify-center p-10">
                <Loader />
              </div>
              :
              <p className="text-center w-full p-10 text-small semi-bold">Products Coming Soon...</p>
            )
        }
      </Container1>
    </div>
  );
};

export default Category;

