import React, { useState, useEffect } from "react";

import "./ProductCard.scss";

import { AddToCartButton } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { findFavouriteItem } from "../../Utils";
import { productAPI } from "../../Api";
import { toast } from "react-toastify";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

import defaultImage from "../../Assets/Images/Home2.png";

import Modal from "../Modal";
import Tooltip from "./Tooltip";

import starIconFilled from '../../Assets/Icons/star-icon.svg'
import starIconOutline from '../../Assets/Icons/star-icon-outline.svg'
import { FaObjectGroup } from "react-icons/fa";

const mapStateToProps = ({ favouriteProducts, auth }) => ({
  favouriteProducts,
  auth,
});

const ProductCard = (props) => {

  const { favouriteProducts, auth } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let price = parseFloat(props.price);
  const { products = [] } = favouriteProducts;
  const { isLoggedIn } = auth;

  // const updateFavouriteProduct = async (value) => {
  //   try {
  //     const payload = {
  //       product_id: props.id,
  //     };
  //     if (value) {
  //       // remove from favourite
  //       const res = await productAPI.deleteFavouriteProduct(payload);
  //       toast.success("Product is removed from your wishlist");
  //       fetchFavouriteProducts();
  //     } else {
  //       // add in favourite
  //       const res = await productAPI.addFavouriteProduct(payload);
  //       toast.success("Product is added in your wishlist");
  //       fetchFavouriteProducts();
  //     }
  //   } catch (error) {
  //     toast.success("Error while updating wishlist");
  //   }
  // };

  // const fetchFavouriteProducts = () => {
  //   dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  // };

  // const isFavourite = findFavouriteItem(props.id, products);

  // const [viewProduct, setviewProduct] = useState({
  //   image: "",
  //   title: "",
  //   price: "",
  //   description: "",
  // })

  // const [modal, setModal] = useState(false);
  const outOfStock = props.quantity_remaining <= 0;

  const [hoverDiv, toggleHoverDiv] = useState(false);

  const newArray = Array.from({ length: 5 }, () => 0);

  return (
    <div
      style={{
        overflow : 'hidden',
      }}
    >
      <div 
        className='relative w-full'
        style={{
          cursor : 'pointer'
        }}
      >
        {/* <div 
          className="w-full relative card-hover"
        > */}
          <img
            // className="product-card-img"
            style={{
              display : 'inline', 
              height : '80%',
              width : '100%',
              overflow : 'hidden',
              borderRadius : '5px',
              objectFit : 'contain',
            }}

            src={props.image}
            // onClick={() => {
            //   setModal(true);
            //   setviewProduct({
            //     image: props.image,
            //     title: props.title,
            //     price: props.price,
            //     description: props.description
            //   })
            // }}
          />
        {/* </div> */}

        <ul 
          className='w-full flex flex-col justify-between ul-style-none h-32'
        >
          <li/>
          <li 
            className='text-uppercase text-large semi-bold text-first text-center'
            style={{
              whiteSpace : 'pre-line',
              overflow : 'hidden',
              textOverflow : 'ellipsis',
              maxWidth : '100%'
            }}
          >
            {
              props.title?.substring(0,35)
            }
          </li>
          <li className="text-lg text-center">
              <p className="w-full h-full text-first regular-bold">
                <span className="ruppe">₹</span>{price || '78'}
              </p>
          </li>
          {
            outOfStock ?
              (
                <p className="out-of-stock">Out Of Stock</p>
              ) 
              :
              (
                // <li className="w-full">
                  <AddToCartButton product={props}/>
                // </li>
              )
          }
        </ul>

      </div>

      {/* <div className="product-mid-text">

        <Tooltip content={props.title}>
          <h3>{props.title.slice(0, 18)}{props.title.length > 18 && "..."}</h3>
        </Tooltip>
        <p>{props.description}</p>

      </div > */}
    </div >
  );
};

export default ProductCard;

ProductCard.defaultProps = {
  image: defaultImage,
};
