import { get, isArray } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ProductCard } from "..";
import { productAPI } from "../../Api";
import Loader from "../Loader";
import "./SearchResults.scss";
const SearchResults = ({search}) => {

  const [searchData, setSearchData] = useState([]);
  const [loadMore, setloadMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchSearchResults = async (pageNumber) => {
    try {
        const response = await productAPI.sellableProductSearch({
            search: search,
            page: pageNumber,
        });

        if(!response.data.next){
          setloadMore(false);
        }
        
        const resData = get(response, "data.results");
        

        if(resData){

          const uniqueData = [
            ...new Set([...searchData, ...resData].map((item) => item.id)),
          ].map((id) => [...searchData, ...resData].find((item) => item.id === id));

          setSearchData(uniqueData);
          setPageNumber(prev => prev + 1);
        }else{
          setloadMore(false);
        }
        
    } catch (error) {
        setloadMore(false);
    }
  };

  useEffect(() => {
    setPageNumber(1);
    setloadMore(true);
    fetchSearchResults(1);
    setSearchData([]);
  }, [search])

  return (
    <div className="search-result-container">
    <h3>Results</h3>
    {
      searchData.length > 0 ?
      <>
      <div className="responsive-cards-3-1">
      {
      
        searchData.map((item) => {
          return (
            <ProductCard
              title={item.product_name}
              quantity={item.description}
              price={item.hasOwnProperty('price_variation') ? item.price_variation['price'] : item.price}
              image={item.photo}
              id={item.id}
              description={item.description}
              category={item.category ? item.category.name : ""}
              oldprice={item.hasOwnProperty('price_variation') ? item.price_variation['market_price']: item.market_price}
              outofstock={item.out_of_stock}
              quantity_remaining={item.quantity_remaining}
            />
          );
        })
      }

      {/* {search.length > 0 && searchData.length === 0 ? (
        <p className="everything-delivery-message">
          Can't find what you're looking for, try
          <Link
            style={{ textDecoration: "none" }}
            to="/everythingdelivery"
            onClick={() => {
              // props.overlayHandler(false);
              // props.resultsHandler(false);
            }}
          >
            {" "}
            <strong className="everything-delivery-message">
              Make Your Own List
            </strong>
          </Link>
        </p>
      ) : null} */}

    </div>
    <center className="m-4">
      {
        loadMore &&
        searchData.length > 0 &&
          <button
            onClick={async () => {
              await fetchSearchResults(pageNumber);
            }}
            className="btn-active btn-md gap-5"
            style={{
              width : '8rem',
              marginTop : '2rem', 
              // padding : '0 5px'
              paddingLeft : '5px',
              paddingRight : '5px'
            }}
          >
            Load More
            <FaArrowAltCircleDown size={'1.2rem'} color={'inherit'}/>
          </button>
      }
    </center> 
    </>
    : 
    (
        loadMore ? 
        <div 
          className="h-full w-full flex flex-row items-center justify-center"
        >
          <Loader/>
        </div> : 
        <p className="text-center m-4">
          No items to show
        </p>
    )
    }
    </div>
  );
};

export default SearchResults;
