import React from 'react'
import Loader from '../Loader'

const Button = ({
    children,
    onClick, 
    active,
    className,
    type,
    loading,
}) => {
  return (
    <button
        type={type}
        onClick={onClick}
        className={`${active ? 'btn-active-bg' : "btn-inactive-bg border"} btn-md text-white round-10  text-md ${className}`}
    >
        {
            loading ? 
            <Loader/>
            :
            children
        }
    </button>
  )
}

export default Button