import React, { useEffect, useState } from "react";
import ProductCard from "../../ProductCard";
import "./Foryou.scss";
import { productAPI } from "../../../Api";
import Leftarrow from "../../../Assets/Icons/left-arrow-white.svg";
import Rightarrow from "../../../Assets/Icons/right-arrow-white.svg";

import { GoArrowLeft, GoArrowRight } from "react-icons/go";

import Carousel from "../../Caraousel/Caraousel.js";

const Foryou = (props) => {

  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    try {
      const response = await productAPI.fetchProducts({ name: props.title, id: props.pk });
      setProducts(response.data.data);
    } catch (error) { 
    }
  };


  useEffect(() => {
    fetchProducts();
  }, [props?.id]);
  
  const scrollProduct = () => {
    const buttonRight = document.getElementById(`slideRightProduct${props.id}`);
    const buttonLeft = document.getElementById(`slideLeftProduct${props.id}`);

    buttonRight.onclick = function () {
      document.getElementById(props.id).scrollLeft += 230;
    };
    
    buttonLeft.onclick = function () {
      document.getElementById(props.id).scrollLeft -= 230;
    };
  };

  return (
    <Carousel
      className={'p-2'}
    >
        { 
          // products.length > 0 ? 
          [1,2,3,4].map((item, index) => {
            // if (item.quantity_remaining >= 1)
              return (
                <div 
                  className="" 
                  style={{
                    width : '13rem',
                    display : 'inline-block',
                    marginRight : '10px',
                  }}
                  // key={item.id}
                >
                  <ProductCard
                    title={`Product ${index+1}`}
                    quantity={'1kg'}
                    price={'200'}
                    image={''}
                    // cart_func={props.cart_handler}
                    id={'ad'}
                    description={'1kg'}
                    category={""}
                    oldprice={'230'}
                    outofstock={false}
                    quantity_remaining={10}
                  />
                </div>
            );
          })
          // : 
          // <p 
          //   className="text-center w-full text-small medium-bold"
          // >
          //   Products Coming Soon...
          // </p>
        }

        {/* {
          products.map((item, index) => {
            if (item.quantity_remaining < 1)
              return (
                <div className="product-card-cell" key={item.id}>
                  <ProductCard
                    title={item.product_name}
                    quantity={item.description}
                    price={item.price}
                    image={item.photo}
                    // cart_func={props.cart_handler}
                    id={item.id}
                    description={item.description}
                    category={item.category ? item.category.name : ""}
                    oldprice={item.market_price}
                    outofstock={item.out_of_stock}
                    quantity_remaining={item.quantity_remaining}
                  />
                </div>
              );
            }
          )
        } */}
    </Carousel>
  );
};

export default Foryou;

Foryou.defaultProps = {
  title: "Best Sellers",
  image: "https://picsum.photos/536/354",
};
