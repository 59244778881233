import React, { useState } from 'react';
import {  InputField } from '../../../Components';
import { findError } from '../../../Utils';
import Loader from '../../Loader';
import Button from '../../../Components/Button/Button';

export default function Login({ submitHandler, controls, errors, showErr, onChange, loading }) {
  const { phone_number, password } = controls;
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  return (
    <div className="flex flex-col gap-15">
      <div/>
      <InputField
        label={'Mobile Number'}
        type='number'
        placeholder='Mobile Number'
        value={phone_number}
        onChange={(e) => onChange(e, 'PHONE')}
        error={findError('phone_number', errors, showErr)}
      />

      <Button
        active={true}
        className="w-full"
        onClick={submitHandler}
      >
        {
          loading  ?
          <Loader /> : 
          'Login Via Otp'
        }
      </Button>
    </div>
  );
}
