import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDOM from 'react-dom'
import './Modal.scss'

import crossIcon from '../../Assets/Icons/cross-icon.png'

const Modal = ({children, closeModal, backgroundColor, style}) => {
    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    return ReactDOM.createPortal(
        <div 
            className={`modal-content-container`}
            onClick={closeModal}
            style={style}
        >
            <div 
                className={`modal-content-wrapper`}
                onClick={stopPropagation}
                style={{
                    backgroundColor : backgroundColor,
                }}
            >
                <button
                    onClick={closeModal}
                    className={'modal-content-wrapper-close'}
                    style={{
                        outline: 'none',
                        border:'none',
                        backgroundColor:'transparent'
                    }}
                >
                    <img  src={crossIcon} />
                </button>
                {
                    children
                }
            </div>  
        </div>, document.getElementById('modal-container')
    )
}

export default Modal;