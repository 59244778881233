import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchAvailableProdOfCat } from "../../Api/travelApi";
import { ProductCardCamp } from "../Home/Home";
import "./AvailableRooms.scss";

const AvailableRooms = () => {
  const { checkin, checkout, noofguest, catId, catName } = useLocation().state;

  const [loading, toggleLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categoryData, setCategoryData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      toggleLoading((prev) => !prev);
      try {
        const response = await fetchAvailableProdOfCat({
          catId: catId,
          params: {
            checkin,
            checkout,
            noofguest,
          },
        });

        if (response.data) {
          setCategoryData(response.data);
          setProducts(response.data.products);
        }
      } catch (err) {
        toast.error("Error in checking availability");
      }
      toggleLoading((prev) => !prev);
    };

    fetchData();
  }, [checkin, catId]);

  return (
    <div
      className="w-full available-rooms"
      style={{
        minHeight: "60vh",
      }}
    >
      {products.length > 0 ? (
        <>
          <div className="space-y-10">
            <p className="text-2xl bold-lg">
              Room is available for {categoryData.name || "Category Name"}
            </p>
            <div className="text-md bold-lg flex flex-col gap-10">
              <span>From : {checkin}</span>
              <span>To : {checkout}</span>
            </div>
          </div>
          <div className="responsive-cards-2-1">
            {products.map((item) => {
              return (
                <ProductCardCamp
                  data={item}
                  details={{
                    checkin: checkin,
                    checkout: checkout,
                    noofguest: noofguest,
                    accomodation: categoryData.name,
                  }}
                />
              );
            })}
          </div>
        </>
      ) : (
        <p className="text-center text-2xl bold-md">Room is not available</p>
      )}
    </div>
  );
};

export default AvailableRooms;
