import React, { useState, useEffect } from "react";
import "./Subtotal.scss";
import { useSelector } from "react-redux";
import { MdOutlineCurrencyRupee } from "react-icons/md";

const mapStateToProps = ({ cart }) => ({
  cart,
});

export default function Subtotal({
  final_price = 0,
  final_item = 0,
  discount_code = "",
  prev_total,
  deliveryCharge,
  total_final_cost,
  paymentmode
}) {
  const {
    cart: cartData,
  } = useSelector(mapStateToProps);
  const { delivery_charge, default_delivery_charge} = cartData
  return (
    <div className="checkout-subtotal-wrapper">
      {discount_code ? (
        <div className="checkout-subtotal">
          <div className="checkout-subtotal-title">Discount Coupon</div>
          <div className="checkout-subtotal-price discount-applied">
            {discount_code}
          </div>
        </div>
      ) : null}

      {paymentmode?
         <div className="checkout-subtotal">
          <div className="checkout-subtotal-title">Payment Mode</div>
          <div className="checkout-subtotal-price discount-applied">
            {paymentmode}
          </div>
        </div>
        :null
      }

      <div className="checkout-subtotal">
        <span className="">Subtotal</span>
        <div className="flex flex-row items-center">
          {/* {parseFloat(final_price) - parseFloat(deliveryCharge) ===
          parseFloat(final_price) ? null : (
            <span className="strike-subtotal">
              <span className="ruppe">₹</span>
              {parseFloat(final_price)}
            </span>
          )}{" "} */}
          <MdOutlineCurrencyRupee />
          {
            total_final_cost ? 
            (parseFloat(total_final_cost)!==0? parseFloat(total_final_cost)-parseFloat(deliveryCharge)
            : "pending")
            : (parseFloat(final_price)!==0? parseFloat(final_price): "pending")
          }
        </div>
      </div>
      <div className="checkout-subtotal">
        <div className="">Total Items</div>
        <div className="">{final_item}</div>
      </div>
      <div className="checkout-subtotal">
        <div className="">Delivery Charge</div>
        <div className="flex flex-row items-center">
          <MdOutlineCurrencyRupee /> 
          {parseFloat(deliveryCharge)? parseFloat(deliveryCharge)>default_delivery_charge? default_delivery_charge: parseFloat(deliveryCharge): default_delivery_charge}
        </div>
      </div>
      {!deliveryCharge && delivery_charge>default_delivery_charge?
        <div className="checkout-subtotal">
          <div className="checkout-subtotal-title">Night Surcharge</div>
          <div className="checkout-subtotal-price">
            <MdOutlineCurrencyRupee /> 
            {delivery_charge-default_delivery_charge}
          </div>
        </div>
      : null}
      {deliveryCharge && deliveryCharge>default_delivery_charge?
        <div className="checkout-subtotal">
          <div className="checkout-subtotal-title">Night Surcharge</div>
          <div className="flex flex-row items-center">
            <MdOutlineCurrencyRupee />  
            {deliveryCharge-default_delivery_charge}
          </div>
        </div>
      : null}

      <div className="checkout-subtotal">
        <div className="">Total Price</div>
        <div className="flex flex-row items-center">
        <MdOutlineCurrencyRupee /> 
        {total_final_cost? (parseFloat(total_final_cost)!==0? parseFloat(total_final_cost) : "pending"): (parseFloat(final_price)!==0? parseFloat(final_price)+ (parseFloat(deliveryCharge)? parseFloat(deliveryCharge): delivery_charge): "pending")}
        </div>
      </div>
    </div>
  );
}
