import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAPI, productAPI } from "../../Api";
import { Navbar, Login, Overlay } from "../../Components";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import SearchResults from "../SearchResults";
import "./Navigation.scss";
import get from "lodash/get";

const mapStateToProps = ({ auth }) => ({
    auth,
});

export default function Navigation({data}) {
    const { auth } = useSelector(mapStateToProps);
    const { showLoginPopup = false, isLoggedIn = false, tenantDetails = {} } = auth;
    const dispatch = useDispatch();
    const [overlayOpen, setoverlayOpen] = useState(false);
    const loginstatesetter = () => {
        dispatch(actionsCreator.SHOW_LOGIN());
    };

    const removeLoginPopup = () => {
        dispatch(actionsCreator.SET_LOGIN({ showLoginPopup: false }));
        setoverlayOpen(false);
        setsearchResults([]);
    };

    const [search, setsearch] = useState("");
    const [searchResults, setsearchResults] = useState([]);
    const [pageNumber, setpageNumber] = useState(1);
    const [userDetails, setUserDetails] = useState({});
    const [loadMore, setloadMore] = useState(true);
    const [showSearchResults, setshowSearchResults] = useState(false);
    const searchTermHandler = (term) => {
        setsearch(term);
    };

    const scrollToEnd = () => {
        setpageNumber(pageNumber + 1);
    };

    useEffect(() => {
        dispatch(actionsCreator.FETCH_TENANT_DETAILS())
        dispatch(actionsCreator.FETCH_CATEGORIES());

        if (isLoggedIn) {
            fetchUserDetails();
        }
        window.addEventListener("logout_user", logoutUser);
        return () => {
            window.removeEventListener("logout_user", logoutUser);
        };
        
    }, []);

    useEffect(() => {
        const fevicon = auth.tenantDetails?.logo || '%PUBLIC_URL%/favicon.ico';
        const title = auth.tenantDetails?.title || 'Bakery';

        document.getElementById('websiteFevicon').setAttribute('href', fevicon);
        document.getElementById('websiteTitle').innerText = title;
    }, [auth])



    const logoutUser = () => {
        dispatch(actionsCreator.LOGOUT_USER());
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    };

    const fetchSearchResults = async () => {
        try {
            const response = await productAPI.sellableProductSearch({
                search: search,
                page: pageNumber,
            });
            const resData = get(response, "data.results");
            if (pageNumber === 1) setsearchResults(resData);
            else setsearchResults([...searchResults, ...resData]);
            setoverlayOpen(true);
        } catch (error) {
            setloadMore(false);
        }
    };

    const bodyVar = document.querySelector("body");

    useEffect(() => {
        if (search !== "") {
            setshowSearchResults(true);
            fetchSearchResults();
        } else {
            setsearchResults([]);
            setoverlayOpen(false);
            setpageNumber(1);
            setloadMore(true);
        }
    }, [search, pageNumber]);

    useEffect(() => {
        setpageNumber(1);
        setloadMore(true);
    }, [search]);

    //click outside to close function
    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setoverlayOpen(false);
                    setsearchResults([]);
                    setshowSearchResults(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const searchRef = useRef();
    useOutsideAlerter(searchRef);

    const fetchUserDetails = async () => {
        dispatch(actionsCreator.FETCH_USER_DETAILS());
    };

    useEffect(() => {
        if (overlayOpen === true) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "auto";
            if (document.querySelector(".navbar-search"))
                document.querySelector(".navbar-search").value = "";
        }
    }, [overlayOpen]);

    return (
        <div style={{
            position : 'relative',
            top : '0',
            width : '100%',
        }}>
            <Navbar
                data={data}
            />
            {
                search.length > 0 &&
                
                    <div ref={searchRef} 
                        style={{
                            height : '60vh',
                            width : '100vw',
                            overflowX : 'hidden',
                            position :'relative'
                        }}
                    >
                        <SearchResults
                            // fetchMoreItems={() => scrollToEnd()}
                            // data={searchResults || []}
                            // loadMore={loadMore}
                            // overlayHandler={setoverlayOpen}
                            // resultsHandler={setshowSearchResults}
                            search={search}
                        />
                    </div>
                
            }
        </div>
    );
}
