import React from "react";
import "./loader.scss";
import { useSelector } from "react-redux";

import { FiLoader } from "react-icons/fi";

const mapStateToProps = ({ message }) => ({
  message,
});

function Loader(props) {
  return (
    <span className="">
      {/* <div class="lds-ring"> */}
        <FiLoader size={'1.5rem'} className="lds-ring" color="#f2f2f2"/>
      {/* </div> */}
    </span>
  );
}

export default Loader;
