import React, { useState } from "react";
import { toast } from "react-toastify";
import { InputField } from "../../../Components";
import { findError } from "../../../Utils";
import Loader from "../../Loader";
import Button from '../../../Components/Button/Button';

export default function PasswordLogin({
  controls,
  submitHandler,
  onChange,
  errors,
  showErr,
  loading,
}) {
  const { phone_number, password } = controls;
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const clickHandler = () => {
    setShowPasswordInput(true);
    if (password) {
      submitHandler();
    }else{
      toast.error("Please type password");
    }
  };
  return (
    <div className="flex flex-col gap-15">
      <div />
      {
      showPasswordInput 
      && (
        <InputField
          label={'Password'}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => onChange(e, "PASSWORD")}
          error={findError("password", errors, showErr)}
        />
      )}

      <Button
        type="button"
        active={false}
        className="text-black"
        onClick={clickHandler}
      >
        {
          loading  ?
          <Loader /> : 
          'Login via Password'
        }
      </Button>

    </div>
  );
}
