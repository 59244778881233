import requestMaker from "../Lib";

export const fetchAvailableProdOfCat = (data) => {
    const { catId } = data;
    const url = `/shop/category/availability/${catId}`;
    const {params} = data;
    const {payload} = data;
    return requestMaker(url, 'get', { params, payload });
};

export const fetchAvailableProd = (data) => {
    const { prodId } = data;
    const url = `/shop/product/availability/${prodId}`;
    const {params} = data;
    const {payload} = data;
    return requestMaker(url, 'get', { params, payload });
};

export const getAllFeaturedProd = (data) => {
    const url = `/shop/featured_products`;
    const {params} = {};
    const {payload} = {};
    return requestMaker(url, 'get', { params, payload });
};

