import React, { useEffect, useRef, useState } from "react";
import "./Banner.scss";
import backImg from "../../../Assets/Images/banner/background.svg";
import playStoreIcon from "../../../Assets/Images/playstore.png";
import appStoreIcon from "../../../Assets/Images/appstore.png";
import deliveryManImg from "../../../Assets/Images/banner/banner_girl.png";
import chocolateCakeImg from "../../../Assets/Images/banner/banner-choclate-image.png";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../..//Redux/actions/actionsCreator";
import discountIMG from "../../../Assets/Images/banner/discountIMG.png";
import blob1 from "../../../Assets/Images/banner/blob1.svg";
import blob2 from "../../../Assets/Images/banner/blob2.svg";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import leftArrow from "../../../Assets/Images/banner/arrow-left.svg";
import rightArrow from "../../../Assets/Images/banner/arrow-right.svg";

import cakeSliderPic1 from "../../../Assets/Images/banner/cake-slider-picture-1.png";
import cakeSliderPic2 from "../../../Assets/Images/banner/cake-slider-picture-2.png";
import cakeSliderPic3 from "../../../Assets/Images/banner/cake-slider-picture-3.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { productAPI } from "../../../Api";
import useDebounce from "../../../Hooks/useDebounce";

const mapStateToProps = ({ isLoggedIn, auth, categories }) => ({
  isLoggedIn,
  auth,
  categories,
});

const Banner = (props) => {
  const [templateCategories, setTemplateCategories] = useState([]);
  const dispatch = useDispatch();

  useEffect(async () => {
    const res = await productAPI.fetchCategories();
    setTemplateCategories(res.data);
    dispatch(actionsCreator.FETCH_CATEGORIES());
  }, []);

  const {
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);
  const { height, width } = useWindowDimensions();
  const { auth } = useSelector(mapStateToProps);
  const { showLoginPopup, isLoggedIn } = auth;
  const [currentValue, setCurrentValue] = useState(0);
  const history = useHistory();

  const [categoryDetails, setCategoryDetails] = useState({
    name: categoryList[0]?.name || "Category Name",
    desc:
      categoryList[0]?.desc ||
      `Here's our best creations that everyone loves. Lightness and
    sweetness of the cake make you want more and more. Start
    from cake, bread and other creations.`,
  });
  const selectCategory = (name, desc) => {
    setCategoryDetails((prev) => ({ ...prev, ["name"]: name, ["desc"]: desc }));
    history.push(`/categories/${name}`);
  };

  const loginStateSetter = () => {
    dispatch(actionsCreator.SHOW_LOGIN());
  };

  const [max, setMax] = useState(categoryList.length);
  const sliderRef = useRef(null);

  const handleSlider = (direction) => {
    const scrollValue = 200;

    if (sliderRef.current) {
      const scrollWidth = sliderRef.current.scrollWidth;

      if (direction > 0) {
        if (currentValue < categoryList.length) {
          setCurrentValue(currentValue + 1);
        }
        const newScrollX = sliderRef.current.scrollLeft + scrollValue;
        if (scrollWidth >= newScrollX) {
          sliderRef.current.scrollLeft = newScrollX;
        }
      } else {
        if (currentValue > 0) {
          setCurrentValue(currentValue - 1);
        }
        const newScrollX = sliderRef.current.scrollLeft - scrollValue;

        if (newScrollX >= 0) {
          sliderRef.current.scrollLeft = newScrollX;
        } else {
          sliderRef.current.scrollLeft = 0;
        }
      }
    }
  };


  return (
    <div className="banner-giga-wrapper">
      {
      true && (
        <div>
          <div className="w-full">
            <div className="w-full overflow-hidden">
              <div 
                className="product-slider scrollbar-hidden"
              >
                {
                  categoryList 
                  &&
                  categoryList.map((category, index) => (
                    <div
                      onClick={() =>
                        selectCategory(category.name, category.description)
                      }
                      className="category-item"
                      style={{
                        cursor : 'pointer',
                        display : 'inline-flex'
                      }}
                    >
                      <img
                        src={category.image}
                        className="card-hover border-red"
                        style={{
                          display : 'inline',
                          objectFit: "contain", 
                          height : '80%',
                          width : '100%',
                        }}
                      />
                      <span 
                        className="text-small text-uppercase text-center medium-bold"
                        style={{
                          overflow : 'hidden',
                          textOverflow : 'ellipsis'
                        }}
                      >
                        {category.name}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Banner;

Banner.defaultProps = {};
